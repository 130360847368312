import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { validateEmail } from 'src/app/utils/utils';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { CustomersService } from 'src/services/customers.service';
import { MessageService } from 'src/services/message.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.page.html',
  styleUrls: ['./add-member.page.scss'],
})
export class AddMemberPage {
  isLoading = false;
  email: string;
  name: string;
  phoneNumber: string;
  notes: string;
  sendEmail = true;
  organizerId: string;
  isAdmin = false;
  hidePhoneNumber = false;
  hideWelcomeOption = true;

  constructor(
    navParams: NavParams,
    authService: AuthService,
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private apiService: ApiService,
    private customersService: CustomersService,
    private analyticsService: AnalyticsService,
  ) {
    this.organizerId = navParams.get('organizerId');
    this.isAdmin = !!navParams.get('isAdmin');
    this.hidePhoneNumber = authService.phoneNumberRequirement === 'none';
    this.hideWelcomeOption = !navParams.get('welcomeEmailEnabled');
  }

  async submit() {
    const cleanEmail = validateEmail(this.email);

    if (!cleanEmail) {
      await this.msgSrvc.show('Whoops! Please enter a valid email.');

      return;
    }

    this.isLoading = true;

    try {
      if (this.organizerId) {
        await this.customersService.upsertCustomerMember({
          customerId: this.organizerId,
          email: cleanEmail,
          name: this.name,
          phoneNumber: this.phoneNumber || undefined,
          notes: this.notes || '',
          muteWelcomeEmail: !this.sendEmail,
          following: true,
        });

        await this.msgSrvc.show('Member added!');
      } else {
        await this.apiService.postPromise(`/users/invite`, {
          email: cleanEmail,
          name: this.name,
          notes: this.notes || '',
          isAdmin: this.isAdmin,
          muteWelcomeEmail: !this.sendEmail,
        });

        const invitationMessage = this.isAdmin
          ? 'Invitation sent!'
          : "Invitation sent! You'll see them here after they log in";
        const directAddMessage = this.isAdmin ? 'New admin added!' : 'New team member added!';
        const successMessage = this.sendEmail ? invitationMessage : directAddMessage;

        await this.msgSrvc.show(successMessage);
      }

      this.analyticsService.trackEvent('Add Member', `Invite ${this.isAdmin ? 'Admin' : 'Team Member'}`, {
        email: cleanEmail,
      });

      this.dismiss(true);
    } catch (err) {
      await this.msgSrvc.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss(success?: boolean) {
    const data =
      success && this.email
        ? {
            email: this.email,
            name: this.name,
            notes: this.name,
          }
        : null;
    this.modalCtrl.dismiss(data);
  }
}
