<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title">
    <h3 *ngIf="!isAdmin">{{ organizerId ? 'Add Community Member' : 'Invite Team Members' }}</h3>
    <h3 *ngIf="isAdmin">Add Admins</h3>
  </div>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label position="stacked">{{organizerId ? 'Email *' : 'Email addresses' }}</ion-label>
      <ion-input
        [(ngModel)]="email"
        [placeholder]="organizerId ? 'Enter email address' : 'Enter emails separated by a comma'"
      ></ion-input>
    </ion-item>

    <ion-item [hidden]="!organizerId">
      <ion-label position="stacked">Name</ion-label>
      <ion-input [(ngModel)]="name" placeholder="Enter name (not required for existing users)"></ion-input>
    </ion-item>

    <ion-item [hidden]="hidePhoneNumber">
      <ion-label position="stacked">Phone number (optional)</ion-label>
      <ion-input [(ngModel)]="phoneNumber" placeholder="Enter valid phone number (optional)"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">{{organizerId ? 'Notes (optional)' : 'Message (optional)' }}</ion-label>
      <ion-textarea
        [(ngModel)]="notes"
        rows="15"
        [placeholder]="organizerId ? 'Enter notes (optional)' : 'Enter a custom welcome message (optional)'"
      ></ion-textarea>
    </ion-item>

    <ion-item [hidden]="hideWelcomeOption">
      <ion-checkbox [(ngModel)]="sendEmail" style="margin-right: 10px"></ion-checkbox>
      <ion-label>Send welcome email</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer class="footer">
  <ion-button (click)="submit()" [disabled]="isLoading">
    {{ organizerId ? 'Add' : 'Send invite' }}
    <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
    <ion-icon name="send" class="icon-padded-left" *ngIf="!organizerId && !isLoading"></ion-icon>
  </ion-button>
</ion-footer>
